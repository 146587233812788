import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import logo from './Logo-Big.jpg';
import './App.css';
import { Accordion, Alert, Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { FaAsterisk } from '@react-icons/all-files/fa/FaAsterisk';
import AddWohnsitzComponent, { Wohnsitz } from './AddWohnsitzComponent/AddWohnsitzComponent';
import WohnsitzZeile from './WohnsitzZeileComponent/WohnsitzZeile';
import AddArbeitComponent, { Arbeit } from './AddArbeitComponent/AddArbeitComponent';
import ArbeitsZeile from './ArbeitsZeileComponent/ArbeitsZeile';
import moment from 'moment';
import JSZip from 'jszip';
import { FaTimesCircle } from '@react-icons/all-files/fa/FaTimesCircle';
import { Constants } from './constants';
import { StatesAlpha2List } from './statesAlpha2List';
import StrafregisterInput from './StrafregisterInput/StrafregisterInput';


function App() {

  const FULL_DATE_FORMAT = 'DD.MM.yyyy';
  const MONTH_DATE_FORMAT = 'MM.yyyy';
  const JSON_DATE_FORMAT = 'YYYY-MM-DD';
  const groupClass = 'mb-3';
  const rowClass = 'py-2';
  const [validated, setValidated] = useState(false);
  const [docFormValidated, setDocFormValidated] = useState(false);

  const [showWohnsitzAdd, setShowWohnsitzAdd] = useState(false);
  const [wohnsitze, setWohnsitze] = useState<Array<Wohnsitz>>([]);

  const [showArbeitAdd, setShowArbeitAdd] = useState(false);
  const [arbeiten, setArbeiten] = useState<Array<Arbeit>>([]);

  const [showDialog, setShowDialog] = useState(false);

  const [geschlechtInvalidMessage, setGeschlechtInvalidMessage] = useState('');
  const [docArtInvalidMessage, setDocArtInvalidMessage] = useState('');
  const [fileInvalidMessage, setFileInvalidMessage] = useState('');
  const [file2InvalidMessage, setFile2InvalidMessage] = useState('');
  const [formErrorMessages, setFormErrorMessages] = useState<Array<string>>([]);

  const [wohnsitzeHasErrors, setWohnsitzeHasErrors] = useState(false);
  const [arbeitenHasErrors, setArbeitenHasErrors] = useState(false);

  const [staat1Invalidity, setStaat1Invalidity] = useState('');

  const [json, setDataObject] = useState<Object>();
  const [jsonUrl, setJsonUrl] = useState('');

  const requiredIcon = <FaAsterisk style={{ color: 'red', paddingLeft: '2px' }} ></FaAsterisk>

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    let correct = true;

    const newErrorMessages: string[] = [];

    if (form.geschlecht.value < 0) {
      form.geschlecht.setCustomValidity('Bitte Geschlecht auswählen');
      setGeschlechtInvalidMessage('Bitte Geschlecht auswählen');
      correct = false;
    }

    if (form.staat_1.value === '') {
      form.staat_1.setCustomValidity('Bitte Staatsangehörigkeit auswählen');
      setStaat1Invalidity('Bitte Staatsangehörigkeit auswählen');
      correct = false;
    }

    if (docForm.current!.art.value < 0) {
      docForm.current!.art.setCustomValidity('Bitte Dokumentart auswählen');
      setDocArtInvalidMessage('Bitte Dokumentart auswählen');
      correct = false;
    } else {
      docForm.current!.art.setCustomValidity('');
      setDocArtInvalidMessage('');
    }

    if (wohnsitze.length === 0) {
      newErrorMessages.push('Keine Wohnsitze angegeben. Wohnsitze lückenlos für die letzen 5 Jahre angeben.');
      setWohnsitzeHasErrors(true);
    }

    if (arbeiten.length === 0) {
      newErrorMessages.push('Keine Beschäftigungsverhältnisse angegeben. Lückenlos für die letzen 5 Jahre angeben.');
      setArbeitenHasErrors(true);
    }


    if (form.checkValidity() === true && docForm.current?.checkValidity() === true &&
      (strafregisterForm.current === null || strafregisterForm.current.checkValidity() === true)) {

      const startMomentForChecks = moment().add(-5, 'year');
      wohnsitze.sort((a, b) => a.von.getTime() - b.von.getTime());
      let aktuellesWohnsitzDatum = startMomentForChecks;
      let wohnsitzOpenEnd = false;
      for (const w of wohnsitze) {
        if (aktuellesWohnsitzDatum.isBefore(moment(w.von).add(-1, 'days'))) {
          newErrorMessages.push('Bitte Wohnsitz zwischen ' + aktuellesWohnsitzDatum.format(FULL_DATE_FORMAT) + ' und ' + moment(w.von).format(FULL_DATE_FORMAT) + ' angeben');
          setWohnsitzeHasErrors(true);
          break;
        }
        if (w.bis === undefined) {
          wohnsitzOpenEnd = true;
          break;
        }
        aktuellesWohnsitzDatum = moment(w.bis);
      }
      if (!wohnsitzOpenEnd && moment().add(-1, 'days').isAfter(aktuellesWohnsitzDatum)) {
        newErrorMessages.push('Bitte Wohnsitz zwischen ' + aktuellesWohnsitzDatum.format(FULL_DATE_FORMAT) + ' und ' + moment().format(FULL_DATE_FORMAT) + ' angeben');
        setWohnsitzeHasErrors(true);
      }

      arbeiten.sort((a, b) => a.von.getTime() - b.von.getTime());
      let aktuellesArbeitsDatum = startMomentForChecks;
      let arbeitenOpenEnd = false;
      for (const a of arbeiten) {
        if (aktuellesArbeitsDatum.isBefore(moment(a.von).add(-28, 'days'))) {
          newErrorMessages.push('Bitte Beschäftigung zwischen ' + aktuellesArbeitsDatum.format(MONTH_DATE_FORMAT) + ' und ' + moment(a.von).format(MONTH_DATE_FORMAT) + ' angeben');
          setArbeitenHasErrors(true);
          break;
        }
        if (a.bis === undefined) {
          arbeitenOpenEnd = true;
          break;
        }
        aktuellesArbeitsDatum = moment(a.bis);
      }

      if (!arbeitenOpenEnd && moment().add(-28, 'days').isAfter(aktuellesArbeitsDatum)) {
        newErrorMessages.push('Bitte Beschäftigung zwischen ' + aktuellesArbeitsDatum.format(MONTH_DATE_FORMAT) + ' und ' + moment().format(MONTH_DATE_FORMAT) + ' angeben. Es dürfen höchstens Lücken von 28 Tagen zwischen zwei Beschäftigungsverhältnissen vorliegen.');
        setArbeitenHasErrors(true);
      }

    }
    else {
      correct = false;
    }

    setFormErrorMessages(newErrorMessages);

    if (correct && newErrorMessages.length === 0) {
      setArbeitenHasErrors(false);
      setWohnsitzeHasErrors(false);
      const dataObject = createJson(form);
      const json = JSON.stringify(dataObject);
      setDataObject(dataObject[0]);
      const blob = new Blob([json], { type: 'application/json' });

      const jsZip = new JSZip();
      jsZip.file('zuep.json', blob);
      if (docForm.current) {
        const file = docForm.current.file.files[0];
        jsZip.file(file.name, file);

        if (docForm.current.file2?.files?.length > 0) {
          const file2 = docForm.current.file2.files[0];
          jsZip.file(file2.name, file2);
        }
      }

      if (strafregisterForm.current != null) {
        const formData = new FormData(strafregisterForm.current);
        for (const key of formData.keys()) {
          const d = formData.get(key);
          const file = d as File;
          jsZip.file(key + '_' + file.name, file);
        }
      }


      jsZip.generateAsync({ type: 'blob' }).then((blob) => {
        const url = URL.createObjectURL(blob);
        setJsonUrl(url);
      });




      /*var a = document.createElement('a');
      a.href = url;
      a.download = 'backup.json';
      a.textContent = 'Download backup.json';

      document.getElementById('json').appendChild(a);*/
      setShowDialog(true);
    }

    setValidated(true);
    setDocFormValidated(true);
  };

  const createJson = (form: HTMLFormElement): Array<Object> => {

    const adressArray: Array<Object> = [];

    for (let w of wohnsitze) {
      const adresse = {
        art: 0, //Wohnsitz = 1 konstant
        land: w.land.toUpperCase(),
        plz: w.plz,
        ort: w.ort,
        strasse: w.strasse,
        zusatz: w.hausnummer,
        von: moment(w.von).format(JSON_DATE_FORMAT),
        bis: w.bis ? moment(w.bis).format(JSON_DATE_FORMAT) : null

      }
      adressArray.push(adresse);
    }

    const workArray: Array<Object> = [];
    for (let w of arbeiten) {
      const arbeit = {
        art: parseInt(w.art),
        name: w.organisation,
        info: w.taetigkeit,
        von: moment(w.von).format(JSON_DATE_FORMAT),
        bis: w.bis ? moment(w.bis).format(JSON_DATE_FORMAT) : null
      }
      workArray.push(arbeit);
    }

    const dokumentArray: Array<Object> = [];
    if (docForm.current) {
      const file = docForm.current.file.files[0];
      const filename = file.name;
      const mimetype = file.type;
      const doc = {
        art: parseInt(docForm.current.art.value),
        ausstellung: moment(docForm.current.ausstellung.value).format(JSON_DATE_FORMAT),
        info: null,
        nummer: docForm.current.nummer.value,
        name: filename,
        mime: mimetype,
        upload: true
      };
      dokumentArray.push(doc);

      if (docForm.current.file2?.files?.length > 0) {
        const file2 = docForm.current.file2.files[0];
        const filename2 = file2.name;
        const mimetype2 = file2.type;
        const doc2 = {
          art: parseInt(docForm.current.art.value),
          ausstellung: moment(docForm.current.ausstellung.value).format(JSON_DATE_FORMAT),
          info: null,
          nummer: docForm.current.nummer.value,
          name: filename2,
          mime: mimetype2,
          upload: true
        };
        dokumentArray.push(doc2);
      }
    }

    const obj = {
      unternehmen: 49,
      titel: form.titel.value,
      vorname: form.vorname.value,
      famname: form.famname.value,
      gebname: form.gebname.value,
      geschlecht: parseInt(form.geschlecht.value),
      gebdatum: form.gebdatum.value,
      geb_ort: form.geb_ort.value,
      geb_ort_plz: form.geb_ort_plz.value,
      geb_ort_land: form.geb_ort_land.value,
      vorname_m: form.vorname_m.value,
      vorname_v: form.vorname_v.value,
      staat_1: form.staat_1.value.toUpperCase(),
      staat_2: form.staat_2.value?.toUpperCase(),
      kontakt_tel: form.kontakt_tel.value,
      kontakt_mail: form.kontakt_mail.value,
      zustimmung: 1,
      suep: null,
      adresse: adressArray,
      beschaeftigung: workArray,
      dokument: dokumentArray
    }

    return [obj];
  }

  const checkFile = (e: ChangeEvent<HTMLInputElement>, invaliditySetter: (s: string) => void) => {
    checkFileElement(e.target, invaliditySetter);
  }

  const checkFileElement = (target: HTMLInputElement, invaliditySetter: (s: string) => void) => {
    if (target.files !== null && target.files?.length > 0) {
      const file = target.files[0];
      if (file.size > 2048000) {
        target.setCustomValidity('Datei zu groß');
        setFileInvalidMessage('Datei muss kleiner als 2 MB sein');
      }
      else {
        target.setCustomValidity('');
        setFileInvalidMessage('');
      }
    }
  }

  const wohnsitzAdd = (wohnsitz: Wohnsitz) => {
    setWohnsitze((wohnsitze) => [...wohnsitze, wohnsitz]);
    setShowWohnsitzAdd(false);
    setWohnsitzeHasErrors(false);
  };

  const arbeitAdd = (arbeit: Arbeit) => {
    setArbeiten((arbeiten) => [...arbeiten, arbeit]);
    setShowArbeitAdd(false);
    setArbeitenHasErrors(false);
  }

  const deleteWohnsitz = (index: number) => {
    setWohnsitze(wohnsitze.filter((_a, i) => i !== index));
  }
  const deleteArbeit = (index: number) => {
    setArbeiten(arbeiten.filter((_a, i) => i !== index));
  }

  const form = useRef<HTMLFormElement>(null);
  const strafregisterForm = useRef<HTMLFormElement>(null);
  const docForm = useRef<HTMLFormElement>(null);

  const submitForm = () => {
    if (form.current)
      form.current.requestSubmit();
  }

  const resetFormValidity = (e: ChangeEvent<HTMLElement>) => {
    const element = e.target as HTMLInputElement;
    if (element.value >= '0') {
      element.setCustomValidity('');
      //setValidated(false);
    }
    else {
      element.setCustomValidity('Wert auswählen');
    }
  }


  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} alt='logo' className='App-logo' />
        <h1>ZÜP Eingabeformular</h1>
        <h3>powered by Flugsportzentrum Tirol</h3>
      </header>
      <Container fluid='md' className='py-4'>
        <Row><Col xs={12}>{requiredIcon} = Pflichtfelder</Col></Row>
        <Form ref={form} noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className={rowClass}>
            <Col md={2} xs={12}>
              <Form.Group className={groupClass}>
                <Form.Label>Titel</Form.Label>
                <Form.Control name='titel' type='text'></Form.Control>
                <Form.Text className="text-muted">
                  Falls vorhanden.
                </Form.Text>
              </Form.Group>
            </Col>
            <Col md={5} xs={12}>
              <Form.Group className={groupClass}>
                <Form.Label>Familienname{requiredIcon}</Form.Label>
                <Form.Control name='famname' type='text' required></Form.Control>
                <Form.Text className="text-muted">
                  Der Familienname vom Ausweisdokument.
                </Form.Text>
              </Form.Group>
            </Col>
            <Col md={5} xs={12}>
              <Form.Group className={groupClass}>
                <Form.Label>Vorname{requiredIcon}</Form.Label>
                <Form.Control name='vorname' type='text' required pattern="^\S*$"></Form.Control>
                <Form.Control.Feedback type='invalid' >
                  Vorname muss angegeben werden und darf keine Leerzeichen enthalten
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                  Nur der erste zusammenhängende Vorname vom Ausweisdokument.
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row className={rowClass}>
            <Col md={6} xs={12}>
              <Form.Group className={groupClass}>
                <Form.Label>Geschlecht{requiredIcon}</Form.Label>
                <Form.Select name='geschlecht' required onChange={resetFormValidity}>
                  <option value='-1'>Geschlecht auswählen</option>
                  <option value='0'>männlich</option>
                  <option value='1'>weiblich</option>
                  <option value='2'>divers</option>
                </Form.Select>
                <Form.Control.Feedback type='invalid' >
                  {geschlechtInvalidMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group className={groupClass}>
                <Form.Label>Geb.Datum{requiredIcon}</Form.Label>
                <Form.Control name='gebdatum' type='date' max={moment().add(-16, 'years').format(JSON_DATE_FORMAT)} required></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className={rowClass}>
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Geb.Name</Form.Label>
                <Form.Control name='gebname' type='text'></Form.Control>
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Geb.Ort{requiredIcon}</Form.Label>
                <Form.Control name='geb_ort' type='text' required></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className={rowClass}>
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Geb.Ort PLZ</Form.Label>
                <Form.Control name='geb_ort_plz' type='text'></Form.Control>
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Geb.Ort Land</Form.Label>
                <Form.Select name='geb_ort_land' onChange={resetFormValidity}>
                  <option value={''}>Land wählen (falls zutreffend)</option>
                  {
                    Object.keys(StatesAlpha2List.ALPHA2CODES).map((key, index) =>
                      <option value={key}>{StatesAlpha2List.ALPHA2CODES[key]}</option>
                    )
                  }
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row className={rowClass}>
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Vorname Mutter{requiredIcon}</Form.Label>
                <Form.Control name='vorname_m' type='text' required pattern="^\S*$"></Form.Control>
                <Form.Control.Feedback type='invalid' >
                  Vorname muss angegeben werden und darf keine Leerzeichen enthalten
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Vorname Vater{requiredIcon}</Form.Label>
                <Form.Control name='vorname_v' type='text' required pattern="^\S*$"></Form.Control>
                <Form.Control.Feedback type='invalid' >
                  Vorname muss angegeben werden und darf keine Leerzeichen enthalten
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className={rowClass}>
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Staatsangehörigkeit 1{requiredIcon}</Form.Label>
                <Form.Select name='staat_1' required onChange={resetFormValidity}>
                  <option value={''}>Bitte Staatsangehörigkeit wählen</option>
                  {
                    Object.keys(StatesAlpha2List.ALPHA2CODES).map((key, index) =>
                      <option value={key}>{StatesAlpha2List.ALPHA2CODES[key]}</option>
                    )
                  }
                </Form.Select>
                <Form.Control.Feedback type='invalid' >
                  {staat1Invalidity}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Staatsangehörigkeit 2</Form.Label>
                <Form.Select name='staat_2' >
                  <option value={''}>Staatsangehörigkeit wählen (falls zutreffend)</option>
                  {
                    Object.keys(StatesAlpha2List.ALPHA2CODES).map((key, index) =>
                      <option value={key}>{StatesAlpha2List.ALPHA2CODES[key]}</option>
                    )
                  }
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row className={rowClass}>
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Kontakt-Telefon{requiredIcon}</Form.Label>
                <Form.Control name='kontakt_tel' type='text' required></Form.Control>
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Kontakt-Mail{requiredIcon}</Form.Label>
                <Form.Control name='kontakt_mail' type='email' required></Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <Row>
          <Accordion defaultActiveKey={['0', '1', '2']} className='py-4' alwaysOpen>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>{docFormValidated && docForm.current?.reportValidity() === false && <FaTimesCircle style={{ paddingRight: '1em' }} color='red' size={40} />}Identitätsdokument</Accordion.Header>
              <Accordion.Body >
                <Form ref={docForm} noValidate validated={docFormValidated}>
                  <Container className='py-4'>
                    <Row className={rowClass}>
                      <Col xs={12}><Alert variant='info'>Bei einem Personalausweis muss Vorder- und Rückseite hochgeladen werden</Alert></Col>
                    </Row>
                    <Row className={rowClass}>
                      <Col xs={12}>
                        <Form.Group>
                          <Form.Label>Dokument-Datei{requiredIcon}</Form.Label>
                          {/* jpeg, jpg, jpe, png, gif, pdf*/}
                          <Form.Control type='file' name='file' accept='image/jpeg,image/png,image/gif,application/pdf' required onChange={(e: ChangeEvent<HTMLInputElement>) => checkFile(e, setFileInvalidMessage)}></Form.Control>
                          <Form.Control.Feedback type='invalid' >
                            {fileInvalidMessage}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className={rowClass}>
                      <Col xs={12}>
                        <Form.Group>
                          <Form.Label>Dokument-Datei (Rückseite)</Form.Label>
                          {/* jpeg, jpg, jpe, png, gif, pdf*/}
                          <Form.Control type='file' name='file2' accept='image/jpeg,image/png,image/gif,application/pdf' onChange={(e: ChangeEvent<HTMLInputElement>) => checkFile(e, setFile2InvalidMessage)}></Form.Control>
                          <Form.Control.Feedback type='invalid' >
                            {file2InvalidMessage}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className={rowClass}>
                      <Col md={4} xs={12}>
                        <Form.Group>
                          <Form.Label>Dokumentart{requiredIcon}</Form.Label>
                          <Form.Select required name='art' onChange={resetFormValidity}>
                            <option value='-1'>Bitte Dokumentart auswählen</option>
                            <option value='1'>Reisepass</option>
                            <option value='2'>Personalausweis</option>
                          </Form.Select>
                          <Form.Control.Feedback type='invalid' >
                            {docArtInvalidMessage}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={4} xs={12}>
                        <Form.Group>
                          <Form.Label>Dokumentnummer{requiredIcon}</Form.Label>
                          <Form.Control type='text' name='nummer' required></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md={4} xs={12}>
                        <Form.Group>
                          <Form.Label>Austellungsdatum{requiredIcon}</Form.Label>
                          <Form.Control required type='date' name='ausstellung' max={moment().format('YYYY-MM-DD')}></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='1'>
              <Accordion.Header>{wohnsitzeHasErrors && <FaTimesCircle style={{ paddingRight: '1em' }} color='red' size={40} />}Wohnsitze</Accordion.Header>
              <Accordion.Body >
                <Container className='py-3'>
                  {!showWohnsitzAdd && wohnsitze.length !== 0 && <Row ><Col xs={{ span: 3, offset: 9 }}>
                    <Button onClick={() => setShowWohnsitzAdd(!showWohnsitzAdd)}><FaPlus ></FaPlus>Wohnsitz hinzufügen</Button>
                  </Col></Row>}
                  {wohnsitze.length > 0 &&
                    <Container>
                      <Row>
                        <Col>Land</Col>
                        <Col className={Constants.HIDDEN_COLUMN_CLASSES}>PLZ</Col>
                        <Col>Ort</Col>
                        <Col>Strasse</Col>
                        <Col className={Constants.HIDDEN_COLUMN_CLASSES}>Hausnummer</Col>
                        <Col>Von-Datum</Col>
                        <Col className={Constants.HIDDEN_COLUMN_CLASSES}>Bis-Datum</Col>
                        <Col>Löschen</Col>
                      </Row>
                      {wohnsitze.map((wohnsitz, index) => (
                        <WohnsitzZeile wohnsitz={wohnsitz} index={index} deleteCallback={deleteWohnsitz} key={index}></WohnsitzZeile>
                      ))
                      }
                    </Container>
                  }
                  {(wohnsitze.length === 0 || showWohnsitzAdd) && <AddWohnsitzComponent callbackOnAdd={wohnsitzAdd} callbackAbort={() => setShowWohnsitzAdd(false)}></AddWohnsitzComponent>}
                  {wohnsitze.length > 0 && wohnsitze.filter((wohnsitz) => wohnsitz.land !== 'AT').length > 0 &&
                    <Form ref={strafregisterForm} noValidate validated={validated}>
                      {[...new Set(wohnsitze.filter(wohnsitz => wohnsitz.land !== 'AT').map((wohnsitz) => wohnsitz.land))].map(land => {
                        if (land !== 'AT')
                          return <StrafregisterInput land={land} />
                        else
                          return null;
                      })}
                    </Form>
                  }
                </Container>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='2'>
              <Accordion.Header>{arbeitenHasErrors && <FaTimesCircle style={{ paddingRight: '1em' }} color='red' size={40} />}Beschäftigungsverhältnisse, Aus- und Weiterbildungen</Accordion.Header>
              <Accordion.Body >
                <Container className='py-3'>
                  {!showArbeitAdd && arbeiten.length !== 0 && <Row ><Col xs={{ span: 5, offset: 7 }} md={{ span: 3, offset: 9 }}>
                    <Button onClick={() => setShowArbeitAdd(!showArbeitAdd)}><FaPlus ></FaPlus>Beschäftigung hinzufügen</Button>
                  </Col></Row>}
                  {arbeiten.length > 0 &&
                    <Container>
                      <Row>
                        <Col className={Constants.HIDDEN_COLUMN_CLASSES}>Art</Col>
                        <Col>Organisation</Col>
                        <Col className={Constants.HIDDEN_COLUMN_CLASSES}>Tätigkeit</Col>
                        <Col>Von-Datum</Col>
                        <Col className={Constants.HIDDEN_COLUMN_CLASSES}>Bis-Datum</Col>
                        <Col>Löschen</Col>
                      </Row>
                      {arbeiten.map((arbeit, index) => (
                        <ArbeitsZeile arbeit={arbeit} key={'a' + index} index={index} deleteCallback={deleteArbeit}></ArbeitsZeile>
                      ))
                      }
                    </Container>
                  }
                  {(arbeiten.length === 0 || showArbeitAdd) && <AddArbeitComponent callbackOnAdd={arbeitAdd} callbackAbort={() => setShowArbeitAdd(false)}></AddArbeitComponent>}

                </Container>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
        {formErrorMessages.length > 0 && formErrorMessages.map((message) => <Row><Col><Alert variant='danger'>{message}</Alert></Col></Row>)}
        {strafregisterForm.current !== null && strafregisterForm.current.checkValidity() === false &&
          <Row><Col><Alert variant='danger'>Für jeden nicht-österreichischen Wohnsitz muss eine Strafregisterbescheinigung hochgeladen werden</Alert></Col></Row>
        }
        <Row>
          <Col><Button onClick={submitForm} variant="success" className="btn-lg" >Daten validieren und abschicken</Button></Col>
        </Row>
      </Container>
      {
        jsonUrl && <Modal show={showDialog} onHide={() => setShowDialog(false)}>
          <Modal.Header closeButton>
            <Modal.Body>Fast geschafft! Datenpaket generieren und per Mail verschicken.</Modal.Body>
          </Modal.Header>
          <Modal.Body><p>1. Datei generieren: <a href={jsonUrl} download={'zuep_' + new Date().toISOString().split('T')[0] + '_' + (json as any)!.famname + '_' + (json as any)!.vorname + '.zip'}>Zip Datei generieren und abspeichern</a></p>
            <p>2. An <a href={'mailto:fabian.moser@gmail.com?subject=Züp Zip ' + (json as any)!.vorname + ' ' + (json as any)!.famname}>fabian.moser@gmail.com</a> senden (<strong>Zip Datei bitte anhängen</strong>).</p>
          </Modal.Body>
        </Modal>
      }
    </div >
  );
}

export default App;

